<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="pdf-preview">
          <div
            v-if="
              !previewContract || !previewLoonheffing || (!ignoreCodeOfConduct && !previewCodeOfConduct)
            "
            class="loading-container"
            style="height: 100%; width: 100%; float: left; display:flex; justify-content: center; align-items: center;"
          >
            <div
              class="loader-cont"
              style="background-color: transparent !important;"
            >
              <img
                style="height: 120px !important; margin: auto; display: block;"
                src="../assets/images/loading-image.svg"
                alt=""
              />
            </div>
          </div>
          <div v-else class="content">

            <pdf 
                v-for="i in pageCountContract"
                :key="`contract-${i}`"
                :src="previewContract"
                :id="`contract-${i}`"
                :page="i"
                style="width:100%; margin:20px auto;"
                :annotation="false"
                :resize="true"
                :text="false"
                scale.sync="scale"
            />

            <pdf 
                v-for="i in pageCountLoonheffing"
                :key="`lhk-${i}`"
                :src="previewLoonheffing"
                :id="`lhk-${i}`"
                :page="i"
                style="width:100%; margin:20px auto;"
                :annotation="false"
                :resize="true"
                :text="false"
                scale.sync="scale"
            />

            <template v-if="previewCodeOfConduct">
                <pdf 
                    v-for="i in pageCountCodeOfConduct"
                    :key="`coc-${i}`"
                    :src="previewCodeOfConduct"
                    :id="`coc-${i}`"
                    :page="i"
                    style="width:100%; margin:20px auto;"
                    :annotation="false"
                    :resize="true"
                    :text="false"
                    scale.sync="scale"
                />
            </template>

          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                v-model="contract.agreed"
                type="checkbox"
                :value="1"
              />
              {{ $t('message.fields.preview.agree') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import pdf from 'pdfvuer'
import Api from "../api/api";

let api = new Api();

export default {
  components: {
    pdf,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: "nl",
    },
    checksum: {
      type: String,
    },
    setSigned: {
      type: Function
    },
    contract: {
      type: Object
    }
  },
  data() {
    return {
      today: moment().format("DD-MM-YYYY"),
      complete: false,
      loading: false,
      loadingContract: false,
      loadingLoonheffing: false,
      loadingCodeOfConduct: false,
      pageCountContract: 0,
      pageCountLoonheffing: 0,
      pageCountCodeOfConduct: 0,
      signed: false,
      previewContract: null,
      previewLoonheffing: null,
      previewCodeOfConduct: null,
      ignoreCodeOfConduct: false,
      confirmRead: false,
      isSignatureFilled: false,
      hasContact: false,
      hasLoonheffing: false,
      hasCodeOfConduct: false
    };
  },

  computed: {
    saveButtonEnabled() {
      if (
        this.confirmRead &&
        this.isSignatureFilled &&
        this.previewContract &&
        this.previewLoonheffing &&
        (this.ignoreCodeOfConduct || this.previewCodeOfConduct)
      ) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.makePdf();
  },

  methods: {
    makePdf: function() {
      this.loadingContract = true;
      this.loadingLoonheffing = true;
      this.loadingCodeOfConduct = true;

      api
        .get(`/contract/${this.hash}/${this.checksum}/preview/${this.language}`)
        .then((response) => {
          this.previewContract = pdf.createLoadingTask({data: atob(response.data)});
          this.previewContract.then(pdf => {
            this.pageCountContract = pdf.numPages;
          });
          this.loadingContract = false;
          this.hasContact = !!response.data;
        })
        .catch(() => {
          this.loadingContract = false;
        });

      api
        .get(
          `/taxdeduction/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          this.previewLoonheffing = pdf.createLoadingTask({data: atob(response.data)});
          this.previewLoonheffing.then(pdf => {
            this.pageCountLoonheffing = pdf.numPages;
          });
          this.loadingLoonheffing = false;
          this.hasLoonheffing = !!response.data;
        })
        .catch(() => {
          this.loadingLoonheffing = false;
        });

      api
        .get(
          `/codeofconduct/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          if (response.status === "success") {
            this.ignoreCodeOfConduct = true;
          }
          this.previewCodeOfConduct = pdf.createLoadingTask({data: atob(response.data)});
          this.previewCodeOfConduct.then(pdf => {
            this.pageCountCodeOfConduct = pdf.numPages;
          });
          this.loadingCodeOfConduct = false;
          this.hasCodeOfConduct = !!response.data;
        })
        .catch(() => {
          this.loadingCodeOfConduct = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-preview {
  max-height: 100vh;
  overflow-y: scroll;
}
</style>
