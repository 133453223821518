<template>
  <div class="overlay">
    <section v-if="type == 'info'" >
      <h2 class="title is-2 mb-6">
        {{ $t('message.complete.success') }}
      </h2>
      <p class="subtitle" v-if="dataChecker">
          {{ $t('message.completed.dataChecker', { appClient: $appClient }) }}
      </p>
      <p class="subtitle">
        {{ $t('message.complete.details', { appClient: $appClient }) }}
      </p>
    </section>
    <section v-if="type == 'signed'" style="margin-top: 100px;" >
      <h1 class="title is-1 mb-6">
         {{ $t('message.signed.success') }}
      </h1>
      <p class="subtitle" v-if="dataChecker">
          {{ $t('message.signed.dataChecker', { appClient: $appClient }) }}
      </p>
      <p class="subtitle">
        {{ $t('message.signed.details', { appClient: $appClient, subject: alreadySigned ? 'data' : 'contract' }) }}
      </p>
    </section>
  </div>
</template>

<script>
  export default {
    props: ["type", "dataChecker", "alreadySigned"],
  }
</script>

<style lang="scss" scoped>
 section {
   display: grid;
   /* margin-top: -10%; */
   place-items: center;
   height: 100%;
 }

 .overlay {
    width: 100%;
    height: 100%;
    z-index: 10002;
    margin:auto;
  }

  p {
    text-align: center;
    margin-bottom: 50px;
  }
</style>